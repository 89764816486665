function applyClickOnGoBackHandlers(){
    document.querySelectorAll('.btn-go-back').forEach(element => {
        element.addEventListener('click', ()=>{
            if (document.referrer != "") {
                history.back();
            } else {
                // ToDo: Fix this: Twig expressions will not work with anymore in extracted js
                window.location.href = "{{ url('events') }}";
            }
        })
    })
}



document.addEventListener('DOMContentLoaded', () => {
    applyClickOnGoBackHandlers();
})
