import EmblaCarousel from 'embla-carousel'
import { addDotBtnsAndClickHandlers } from './embla/EmblaCarouselDotButton'
function initHighlightsCarousel() {
    let selector = '.event-highlights .embla';
    const emblaNode = document.querySelector(selector)
    if (emblaNode) {
        const options = {
            loop: false
        }
        const emblaApi = EmblaCarousel(emblaNode, options)
        const dotsNode = emblaNode.querySelector('.embla__dots')

        const removeDotBtnsAndClickHandlers = addDotBtnsAndClickHandlers(
            emblaApi,
            dotsNode
        );

        emblaApi.on('destroy', removeDotBtnsAndClickHandlers);
/*
        let nextButton = emblaNode.querySelector('.embla__button--next');
        nextButton?.addEventListener('click', () => {
            emblaApi.scrollNext()
        })


        let prevButton = emblaNode.querySelector('.embla__button--prev');
        prevButton?.addEventListener('click', () => {
            emblaApi.scrollPrev()
        })*/
    } else {
        console.warn(`No element found for selector ${selector}`)
    }

}


function initEventCategoryCardsCarousel() {
    const emblaNodes = document.querySelectorAll('.event-category-carousel .embla')

    const options = {
        loop: false,
        dragFree: false,
        align: 'center',
        containScroll: 'trimSnaps'
    }

    emblaNodes.forEach((emblaNode) => {

        const emblaApi = EmblaCarousel(emblaNode, options)

        let nextButton = emblaNode.querySelector('.embla__button--next');
        nextButton?.addEventListener('click', () => {
            emblaApi.scrollNext()
        })


        let prevButton = emblaNode.querySelector('.embla__button--prev');
        prevButton?.addEventListener('click', () => {
            emblaApi.scrollPrev()
        })
    })
}

document.addEventListener('DOMContentLoaded', () => {
    initHighlightsCarousel();
    initEventCategoryCardsCarousel();
})

