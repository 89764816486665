import flatpickr from "flatpickr";
import {German} from "flatpickr/dist/l10n/de";
import {Polish} from "flatpickr/dist/l10n/pl";
import {Czech} from "flatpickr/dist/l10n/cs";
import {format, nextFriday, nextSunday, isFriday, isSameDay, isToday, isTomorrow} from 'date-fns'

export default class DurationFilter {

    private selector = '#finder-datepicker';
    private flatpickrElement = null
    private today = new Date();

    init(languageIsoCode2: string, options: any = {}) {
        const element: Element = document.querySelector(this.selector);
        options = {...this.getDefaultOptions(), ...options};
        if (element) {
            this.setLanguage(languageIsoCode2);
            this.flatpickrElement = flatpickr(element, options);
            //this.addEventHandlers();
        } else {
            console.error("No matching element found for selector " + this.selector);
        }

    }

    setDurationButtonActiveState(from: Date, to: Date) {
        if (isSameDay(from, to)) {
            if (isToday(from)) {
                this.setPeriodSelectorActive(document.getElementById('finder-set-today'));
            }
            if (isTomorrow(from)) {
                this.setPeriodSelectorActive(document.getElementById('finder-set-tomorrow'));
            }
        } else {
            if (isSameDay(nextFriday(this.today), from) && isSameDay(nextSunday(this.today), to)) {
                this.setPeriodSelectorActive(document.getElementById('finder-set-weekend'));
            } else {
                this.setPeriodSelectorActive(document.getElementById('finder-set-custom'));
            }
        }
    }


    getDefaultOptions() {
        return {
            altInput: true,
            altFormat: "d.m.y",
            dateFormat: "c",
            defaultDate: [new Date(), new Date().fp_incr(1)],
            minDate: new Date().fp_incr(-2),
            maxDate: new Date().fp_incr(365),
            mode: "range",
            weekNumbers: true,
            onChange: (event) => {
                console.debug("onChange")
            },
            onClose: (event) => {
                this.removeActiveFromAllPeriodSelectors()
                document.querySelector('div.finder-period-selector').classList.add('active')

                let from, to = null;

                if (this.flatpickrElement.selectedDates.length > 1) {
                    from = this.flatpickrElement.selectedDates[0];
                    to = this.flatpickrElement.selectedDates[1];
                }


                this.updateInputFields(from, to);
                this.submit()
            },
            onOpen: (event) => {
                console.debug("onOpen")
            },
            onReady: (event) => {
                console.debug("onReady")
            },
        };
    }

    addEventHandlers() {
        document.getElementById('finder-set-today').addEventListener('click', (event) => this.setToday(event))
        document.getElementById('finder-set-tomorrow').addEventListener('click', (event) => this.setTomorrow(event))
        document.getElementById('finder-set-weekend').addEventListener('click', (event) => this.setWeekend(event))
    }

    setLanguage(isoCode: string) {
        switch (isoCode) {
            case 'de':
                flatpickr.localize(German);
                break;
            case 'cs':
                flatpickr.localize(Czech);
                break;
            case 'pl':
                flatpickr.localize(Polish);
                break;

        }
    }

    submit() {
        return;
        let form = document.querySelector('#finder form');
        form.submit();
    }

    removeActiveFromAllPeriodSelectors() {
        document.querySelector('#date-buttons .finder-period-selector.active')?.classList.remove('active')
    }

    setPeriodSelectorActive(element) {
        this.removeActiveFromAllPeriodSelectors()
        element.classList.add('active')
    }

    setToday(event) {
        this.setPeriodSelectorActive(event.target)
        // flatpickr.formatDate(new Date(), "Y-m-d h:i K")
        this.flatpickrElement.setDate([this.today, this.today])
        this.updateInputFields(this.today, this.today,);
        this.submit();
    }

    setTomorrow(event) {
        this.setPeriodSelectorActive(event.target)
        let tomorrow = this.today.fp_incr(1);
        this.flatpickrElement.setDate([tomorrow, tomorrow])
        this.updateInputFields(tomorrow, tomorrow);
        this.submit();
    }

    updateInputFields(from, to) {

        let fromFormatted = format(from, 'yyyy-MM-dd');
        let toFormatted = format(to, 'yyyy-MM-dd');

        document.getElementById('duration-from').value = fromFormatted;
        document.getElementById('duration-to').value = toFormatted;
    }

    setWeekend(event) {
        this.setPeriodSelectorActive(event.target)
        let friday = null;
        if (isFriday(this.today)) {
            friday = this.today;
        } else {
            friday = nextFriday(this.today)
        }

        let sunday = nextSunday(friday);

        this.flatpickrElement.setDate([friday, sunday])

        this.updateInputFields(friday, sunday);
        this.submit();
    }
}
