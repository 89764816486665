export default {
    events: {
        LocationFilterChangeLocation: 'LocationFilterChangeLocation'
    },
    selectors: {
        idLocationNameInput: 'location-name-input'
    },
    autocomplete: null,
    isInitialized: false,
    options: {
        centerForAutoComplete: {lat: 50.8999681, lng: 14.8029971},
        useGlobalGoogleMapsInstance: false
    },
    init() {
        this.getInputElement().addEventListener('focusin', () => this.onFocusInHandler())
    },
    getInputElement() {
        return document.getElementById(this.selectors.idLocationNameInput);
    },
    initAutocomplete() {
        // https://developers.google.com/maps/documentation/javascript/place-data-fields
        const defaultBounds = {
            north: this.options.centerForAutoComplete.lat + 0.3,
            south: this.options.centerForAutoComplete.lat - 0.3,
            east: this.options.centerForAutoComplete.lng + 0.3,
            west: this.options.centerForAutoComplete.lng - 0.3,
        };

        this.autocomplete = new google.maps.places.Autocomplete(
            /** @type {HTMLInputElement} */
            (this.getInputElement()), {
                types: ['geocode'],
                fields: ['name', 'geometry.location'],
                bounds: defaultBounds,
                strictBounds: true
            });

        google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
            this.setLocation(this.autocomplete.getPlace());
        });
    },
    onFocusInHandler() {
        if (!this.isInitialized && !this.useGlobalGoogleMapsInstance) {
            this.loadGMapsResources();
            this.isInitialized = true;
        }

        if (!this.isInitialized && this.useGlobalGoogleMapsInstance) {
            this.initAutocomplete();
            this.isInitialized = true;
        }
        //record.onCustomLocation();
    },
    loadGMapsResources() {
        const script = document.createElement('script');
        let callback = '&callback=finder.googleMapsPlacesInitCallback'
        script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyBHgqjosb62HO_aFpxhuVlml3yp9N7c5Tc&libraries=places' + callback;
        document.head.appendChild(script);
        /*        script.addEventListener('load', () => {
                    this.initAutocomplete();
                });*/
    }
    ,
    setLocation(place) {
        document.getElementById('location-lat-input').value = place.geometry.location.lat();
        document.getElementById('location-lng-input').value = place.geometry.location.lng();
        this.getInputElement().dispatchEvent(new CustomEvent(this.events.LocationFilterChangeLocation, {
            bubbles: true,
            detail: place
        }))
    }
    ,
    geolocate() {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                let geolocation = new google.maps.LatLng(
                    position.coords.latitude, position.coords.longitude);
                let circle = new google.maps.Circle({
                    center: geolocation,
                    radius: position.coords.accuracy
                });
                this.autocomplete.setBounds(circle.getBounds());
            });
        }
    }
    ,
    locateMe(element) {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    const pos = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude,
                    };
                    alert('Location found', pos);
                },
                () => {
                    console.log('Error: The Geolocation service failed.');
                    alert('Error: The Geolocation service failed.');
                }
            );
        } else {
            // Browser doesn't support Geolocation
            console.log('Error: Your browser doesn\'t support geolocation.');
            alert('Error: Your browser doesn\'t support geolocation.');
        }
    }
};
