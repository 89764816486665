import {MainNavigationScrollHandler} from "../../common/navigation/MainNavigationScrollHandler";

function apply3MagMenuButtonHandler() {

    function showOverlay(show: Boolean) {
        let overlayElement = document.getElementById('eee-overlay-menu');
        if (overlayElement) {
            overlayElement.style.display = show ? 'flex' : 'none';
        }
    }

    // close on click on background
    let backgroundElement = document.getElementById('eee-overlay-menu');
    backgroundElement?.addEventListener('click', (event) => {
        if (event.target === backgroundElement) {
            showOverlay(false);
            // TODo scroll to top
        }
    })

    let buttonElement = document.getElementById('eee-btn-show-overlay-menu');

    if (buttonElement) {
        buttonElement.addEventListener('click', () => {
            showOverlay(true)

        })

        let allButtonElements = document.querySelectorAll('#eee-overlay-menu ul li a');

        allButtonElements.forEach(element => {
            element.addEventListener('click', () => {
                showOverlay(false)
            })
        })
    }
}

function applyGlobalSearchButtonHandler() {

    let buttonElement = document.getElementById('eee-btn-show-global-search-field');

    function showOverlay(show: boolean) {
        let element = document.getElementById('eee-overlay-global-search');
        if (element) {
            element.style.display = show ? 'flex' : 'none';
        }
    }

    if (buttonElement) {
        buttonElement.addEventListener('click', () => {
            showOverlay(true)
        })
    }

    let overlayGlobalSearchElement = document.getElementById('eee-overlay-global-search')
    if (overlayGlobalSearchElement) {
        overlayGlobalSearchElement.addEventListener('click', (event) => {
            if (event.target == overlayGlobalSearchElement) {
                showOverlay(false);
            }
        })
    }
}


function applyLanguageSwitcherHandlers() {

    let element = document.getElementById('btn-language-switcher');
    let menuElement = document.getElementById('menu-language-switcher');

    function showLanguageSwitcher(show: boolean) {
        if (show) {
            menuElement?.classList.remove('hidden');
        } else {
            menuElement?.classList.add('hidden');
        }
    }

    element?.addEventListener('click', (event) => {
        menuElement?.classList.toggle('hidden');
    })

}

/**
 * e.g. <button class="custom-display-toggle" data-target="#my-modal">Toggle my modal</button>
 */
function applyCustomDisplayToggleHandlers() {
    document.querySelectorAll('.custom-display-toggle').forEach((element => {
        element.addEventListener('click', (event) => {
            event.currentTarget?.classList.toggle('active');
            let targetSelector = event.currentTarget.dataset?.target;
            if (targetSelector) {
                let targetElements = document.querySelectorAll(targetSelector);
                targetElements.forEach((element) => {
                    element.classList.toggle('hidden');
                })
            } else {
                console.debug(`No data-target defined on ${element.classList.toString()}`)
            }
        })
    }))
}

function applyMenuHandlers() {
    apply3MagMenuButtonHandler();
    applyGlobalSearchButtonHandler();
    applyLanguageSwitcherHandlers();
    applyCustomDisplayToggleHandlers();
}

document.addEventListener('DOMContentLoaded', () => {
    applyMenuHandlers();

    let scrollHandler = new MainNavigationScrollHandler();
    scrollHandler.start();
})


