import rangeFilter from "./finder/rangeFilter";
import locationFilter from "./finder/locationFilter";
import DurationFilter from "./finder/durationFilter"

export default {
    options: {
        location: {
            center: {
                lat: null,
                lng: null,
            },
            radius: 45,
        },
        period: {
            startDateTime: null,
            endDateTime: null,
        }
    },
    init() {

        if (document.getElementById('finder') === null) {
            console.log('No #finder element within document.')
            return;
        }

        locationFilter.init();

        this.addEventListeners();

        const durationFilter = new DurationFilter();

        let languageIsoCode = window.countryCodeAlpha2 ?? 'en';

        let from = new Date(document.getElementById('duration-from').value);
        let to = new Date(document.getElementById('duration-to').value);

        durationFilter.init(languageIsoCode, {
            defaultDate: [from, to],
        });
        window.durationFilter = durationFilter;


        /*        rangeFilter.init({
                    currentSiteName: "de"
                });*/
    },


    addEventListeners() {
        // document.addEventListener(locationFilter.events.LocationFilterChangeLocation, (event) => {
        //     this.submitForm();
        // })
    },
    googleMapsPlacesInitCallback() {
        locationFilter.initAutocomplete();
    },
    submitForm() {
        let form = document.querySelector('#finder form');
        form.submit();
    },
    setRadius(element, km) {
        document.querySelector('#btn-group-radius .active').classList.remove('active');
        element.classList.add('active')
    },

    onSetFilterRadioButtonClicked(button) {
        this.submitForm();
    },

    setDateInput(startDate, endDate) {
        document.getElementById('date-start').value = startDate;
        document.getElementById('date-end').value = endDate;
    },

    setDateType(value) {
        document.getElementById('date-type').value = value;
    },

    categoriesCheckAll(checked) {
        document.querySelectorAll('form #finder-event-category-selection ul input[type=checkbox]').forEach(checkbox => {
            checkbox.checked = checked;
        })
    }
};



